.form {
  width: 100%;
  padding: var(--search-form-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-bgcolor-opacity);
  z-index: 2;
  transition: all 0.3s ease;
}

.inputContainer {
  position: relative;
  margin: auto var(--margin-sm);
  display: flex;
  align-items: center;
}

.input {
  border-radius: var(--border-radius-sm);
  border: none;
  outline: none;
  max-width: var(--search-size);
  padding: calc(var(--margin-sm) * 0.5) var(--margin-sm);
  padding-right: 35px;
  color: var(--third-color);
  font-size: var(--font-size-md);
  font-weight: 300;
  background-color: var(--header-bgcolor-opacity);
  transition: all 0.3s ease;
}

.input::placeholder {
  color: var(--third-color);
  opacity: 0.8;
}

.input:hover,
.input:focus {
  background-color: var(--header-bgcolor);
}

.inputSubmit {
  position: absolute;
  right: 0;
  height: 100%;
  color: var(--secondary-color);
  border: none;
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.inputSubmit i {
  padding: calc(var(--margin-sm) * 0.5) var(--margin-sm);
  height: 100%;
  display: flex;
  align-items: center;
  font-size: calc(var(--font-size-md) * 1.3);
}

.inputSubmit > .hovered {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
}

.inputSubmit:hover > .hovered {
  opacity: 1;
}
