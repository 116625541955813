.gallery {
  display: flex;
  flex-direction: column;
  gap: var(--margin-md);
}

.gallery > p {
  font-size: calc(var(--font-size-xl) * 1.4);
  color: var(--header-color);
  letter-spacing: 1px;
}

.gallery > ul {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--gallery-picture-size), 1fr)
  );
  list-style: none;
  gap: 2px;
}
