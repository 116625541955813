.slider {
  padding: var(--slider-padding);
  width: var(--slider-width);
  overflow: hidden;
  border: 1px solid transparent;
  z-index: 2;
}

.slider :global(.swiper) {
  width: calc(var(--slider-width) * 0.86);
  overflow: visible;
}

.slider :global(.swiper-scrollbar) {
  bottom: -27px;
  background-color: var(--secondary-bgcolor-opacity3);
  background-color: transparent;
}

.slider :global(.swiper-scrollbar-drag) {
  background-color: var(--secondary-bgcolor);
  cursor: pointer;
}

.slide {
  width: var(--slide-width);
  max-width: var(--slide-max-width);
  background-size: contain;
  cursor: pointer;
  user-select: none;
  transition: transform var(--transition-speed-normal) ease;
}

.slide:global(.swiper-slide-active) {
  transform-origin: 0%;
}

.slide:last-child {
  transform-origin: 100%;
}

.slide:hover {
  transform: translateY(8px);
  z-index: 1;
}

.mask {
  padding-right: 0px;
  --scrollbar-width: 0px;
  --mask-size: 7%;
  --mask-image-content: linear-gradient(
    to right,
    transparent,
    #000 var(--mask-size),
    #000 calc(100% - var(--mask-size)),
    transparent
  );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
}
