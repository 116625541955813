.personInfo {
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.image {
  position: relative;
  overflow: hidden;
}

.loadedImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: top;
  transition: transform var(--transition-speed-normal);
}

.personInfo:hover img {
  transform: scale(1.05);
}

.defaultImage {
  display: block;
  width: 100%;
  opacity: 0.5;
  transition: transform var(--transition-speed-normal);
}

.info {
  padding: var(--margin-sm);
  display: flex;
  flex-direction: column;
  row-gap: calc(var(--margin-sm) / 2);
  background-color: var(--header-bgcolor-opacity);
}

.name {
  font-size: var(--font-size-md);
  letter-spacing: 0.5px;
}

.character {
  font-size: var(--font-size-sm);
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
}
