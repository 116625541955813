.switcherIcon {
  border: none;
  margin: auto var(--margin-sm);
  height: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--third-color);
  transition: all 0.3s ease;
}

.switcherIcon:hover {
  transform: scale(1.1);
}

.switcherIcon > div {
  height: 14px;
  display: flex;
}

.box {
  display: block;
  margin-left: 2px;
  height: 100%;
  width: 2px;
  transform-origin: bottom;
  transform: scaleY(0);
  background-color: var(--third-color);
}
