.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-bgcolor);
  background-image: url("../../assets/images/header-bg.jpg");
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: bottom;
  user-select: none;
  animation: bg-zoom 30s infinite;
}

.header img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
}

@keyframes bg-zoom {
  0% {
    background-size: auto 100%;
  }
  50% {
    background-size: auto 105%;
  }
  100% {
    background-size: auto 100%;
  }
}

.header h1 {
  margin: var(--margin-xl);
  display: flex;
  flex-direction: var(--header-title-flex-direction);
  column-gap: var(--margin-xl);
  align-items: center;
}

.header h1 span {
  font-family: var(--secondary-font);
  font-size: var(--font-size-xxl);
  line-height: calc(var(--font-size-xxl) * 1.4);
  font-weight: 300;
  text-align: center;
  letter-spacing: 5px;
  color: var(--header-color);
  transition: all 0.3s ease;
}

.glitch {
  color: var(--header-color);
  font-size: 7em;
  font-weight: bold;
  position: relative;
  width: auto;
  margin: 0 auto;
  letter-spacing: 1px;
  z-index: 3;
}

@keyframes noise-anim {
  0% {
    clip: rect(4px, 9999px, 75px, 0);
  }
  5% {
    clip: rect(25px, 9999px, 72px, 0);
  }
  10% {
    clip: rect(27px, 9999px, 74px, 0);
  }
  15% {
    clip: rect(28px, 9999px, 79px, 0);
  }
  20% {
    clip: rect(94px, 9999px, 54px, 0);
  }
  25% {
    clip: rect(76px, 9999px, 60px, 0);
  }
  30% {
    clip: rect(10px, 9999px, 71px, 0);
  }
  35% {
    clip: rect(67px, 9999px, 28px, 0);
  }
  40% {
    clip: rect(1px, 9999px, 61px, 0);
  }
  45% {
    clip: rect(67px, 9999px, 31px, 0);
  }
  50% {
    clip: rect(62px, 9999px, 68px, 0);
  }
  55% {
    clip: rect(36px, 9999px, 99px, 0);
  }
  60% {
    clip: rect(18px, 9999px, 11px, 0);
  }
  65% {
    clip: rect(23px, 9999px, 94px, 0);
  }
  70% {
    clip: rect(20px, 9999px, 75px, 0);
  }
  75% {
    clip: rect(60px, 9999px, 41px, 0);
  }
  80% {
    clip: rect(41px, 9999px, 92px, 0);
  }
  85% {
    clip: rect(17px, 9999px, 17px, 0);
  }
  90% {
    clip: rect(33px, 9999px, 30px, 0);
  }
  95% {
    clip: rect(51px, 9999px, 37px, 0);
  }
  100% {
    clip: rect(36px, 9999px, 100px, 0);
  }
}
.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 rgb(0, 0, 0);
  top: 0;
  color: var(--header-color);
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.delayed:after {
  animation-delay: 0.5s;
}

.toolbar {
  padding: var(--margin-md);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: var(--header-toolbar-flex-direction);
  justify-content: var(--header-toolbar-justify-content);
  align-items: flex-end;
  z-index: 2;
}
