.fogwrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  z-index: 1;
}

.foglayer_01 {
  top: 0;
  width: 200%;
  height: 100%;
  position: absolute;
  animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  -webkit-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
}
.foglayer_01 .image01,
.foglayer_01 .image02 {
  background: url("../../assets/images/fog3.png") repeat top left transparent;
  background-size: 100%;
  width: 50%;
  height: 100%;
  float: left;
}

.foglayer_02 {
  width: 200%;
  height: 100%;
  position: absolute;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -webkit-animation: foglayer_01_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}
.foglayer_02 .image01,
.foglayer_02 .image02 {
  background: url("../../assets/images/fog4.png") repeat center center
    transparent;
  background-size: 100%;
  width: 50%;
  height: 100%;
  float: left;
}

.foglayer_03 {
  width: 200%;
  height: 100%;
  position: absolute;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -webkit-animation: foglayer_01_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}
.foglayer_03 .image01,
.foglayer_03 .image02 {
  background: url("../../assets/images/fog4.png") repeat top left transparent;
  background-size: 100%;
  width: 50%;
  height: 100%;
  float: left;
}

.cover .foglayer_01 .image01,
.cover .foglayer_01 .image02,
.cover .foglayer_02 .image01,
.cover .foglayer_02 .image02,
.cover .foglayer_03 .image01,
.cover .foglayer_03 .image02 {
  background-size: 100% 100%;
}

@keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
