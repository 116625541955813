.mainInfo {
  display: flex;
  flex-direction: column;
  font-weight: 300;
}

.mainInfo h2 {
  font-family: var(--primary-font);
  font-size: calc(var(--font-size-xl) * 1.5);
  font-weight: 500;
  letter-spacing: 1px;
}

.mainInfo > div {
  display: flex;
  column-gap: var(--margin-lg);
  opacity: 0.8;
}

.mainInfo > p {
  font-weight: 400;
}

.img {
  margin-right: var(--margin-lg);
  margin-bottom: var(--margin-sm);
  float: left;
  border-radius: var(--film-poster-border-radius);
  border: var(--film-poster-border);
  align-self: start;
  width: var(--film-poster-size);
}

.vote {
  display: flex;
  column-gap: var(--margin-sm);
  align-items: center;
  margin: var(--margin-xl) 0;
}

.vote p:first-child {
  padding: 0 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--secondary-font);
  font-size: var(--font-size-md);
  font-weight: 300;
  border-radius: var(--border-radius-sm);
  background-color: var(--secondary-bgcolor-opacity);
  letter-spacing: 4px;
  cursor: pointer;
  transform: skewX(-25deg);
  transition: transform var(--transition-speed-fast) ease;
}

.vote p:first-child:hover {
  transform: skewX(-25deg) scale(1.05);
}

.vote span {
  margin-left: 3px;
  align-self: start;
  font-family: var(--primary-font);
  font-size: var(--font-size-sm);
}

.tagline {
  margin: var(--margin-lg) 0;
  font-style: italic;
  opacity: 0.8;
}

.secondaryInfo {
  margin: var(--margin-lg) 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: var(--margin-lg);
  line-height: 180%;
}

.secondaryInfo > p:nth-child(even) {
  font-weight: 300;
  opacity: 0.8;
}

.overview {
  font-style: italic;
  opacity: 0.8;
}

.overview i {
  margin-right: var(--margin-sm);
  font-size: calc(var(--font-size-xl));
  color: var(--header-color);
  transform: scale(1.5);
}

.overview i:last-child {
  margin-right: 0;
  margin-left: var(--margin-sm);
  align-self: end;
}
