@font-face {
  font-family: Bloodseeker;
  src: url("../public/BloodseekerDemo-DxW1.ttf");
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-font: "Oswald", sans-serif;
  --secondary-font: "Bloodseeker", "Creepster", cursive;

  --color-1: #ff2424;
  --color-2: #a70101;
  --color-2a: #a70101d2;
  --color-2b: #a70101aa;
  --color-2c: #a7010132;
  --color-3: #333;
  --color-3a: #333;
  --color-4: #fff;
  --color-4a: #fffc;
  --color-5: #212121;
  --color-5a: #212121;
  --color-6: #000;
  --color-6a: #0008;
  --color-6b: #0009;
  --color-6c: #000c;

  --primary-color: var(--color-2);
  --secondary-color: var(--color-4);
  --secondary-color-opacity: var(--color-4a);
  --third-color: var(--color-4);
  --header-color: var(--color-1);
  --primary-bgcolor: var(--color-5);
  --secondary-bgcolor: var(--color-2);
  --secondary-bgcolor-opacity: var(--color-2a);
  --secondary-bgcolor-opacity2: var(--color-2b);
  --secondary-bgcolor-opacity3: var(--color-2c);
  --third-bgcolor: var(--color-3);
  --header-bgcolor: var(--color-6);
  --header-bgcolor-opacity: var(--color-6a);
  --header-bgcolor-opacity2: var(--color-6b);
  --header-bgcolor-opacity3: var(--color-6c);

  --margin-sm: 1rem;
  --margin-md: 2rem;
  --margin-lg: 3rem;
  --margin-xl: 4rem;

  --font-size-sm: 1.3rem;
  --font-size-md: 1.5rem;
  --font-size-lg: 1.8rem;
  --font-size-xl: 2rem;
  --font-size-xxl: 6rem;

  --avatar-size: 90px;
  --avatar-size-sm: 50px;

  --border-size: 6px;
  --border-size-sm: 3px;

  --border-radius-sm: 5px;
  --border-radius-md: 8px;

  --header: 18vh;

  --transition-speed-fast: 0.2s;
  --transition-speed-normal: 0.3s;
  --transition-speed-slow: 0.5s;

  --pagination-size: 80%;
  --pagination-font-size: var(--font-size-md);
  --slide-max-width: 200px;
  --slider-width: 98%;
  --slider-padding: 20px 0 27px;

  --card-info-height: 90px;
  --card-more-info-height: 300px;

  --film-poster-size: 30%;
  --film-poster-border: 8px solid var(--secondary-bgcolor-opacity);
  --film-poster-border-radius: var(--border-radius-sm);

  --gallery-picture-size: 220px;

  --person-size: minmax(120px, 1fr);

  --player-volume-display: block;
  --header-title-flex-direction: column;
  --header-toolbar-flex-direction: column;
  --header-toolbar-justify-content: normal;

  --footer-padding: var(--margin-sm);
  --footer-logo-height: 1.2rem;

  --search-form-padding: var(--margin-sm);
  --search-size: 145px;
  --switcher-size: 135px;

  --fog-opacity: 0.5;
}

html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  font-family: var(--primary-font);
  -webkit-tap-highlight-color: transparent;
  color: var(--secondary-color);
  background-color: var(--primary-bgcolor);
  background-position: center 200px;
  background-repeat: no-repeat;
}
a {
  text-decoration: none;
}
button {
  font-family: inherit;
  padding: 0;
  border: 1px solid;
  background: none;
  outline: none;
  cursor: pointer;
}

input {
  font-family: inherit;
}

@media (max-width: 576px) {
  :root {
    --font-size-xxl: 4rem;
    --gallery-picture-size: 140px;

    --film-poster-border: 4px solid var(--secondary-bgcolor-opacity);

    --person-size: 30%;
  }
}

@media (max-width: 480px) {
  :root {
    --margin-sm: 1rem;
    --margin-md: 1rem;
    --margin-lg: 1.5rem;
    --margin-xl: 2rem;

    --font-size-sm: 1.1rem;
    --font-size-md: 1.3rem;
    --font-size-lg: 1.8rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 1.8rem;

    --pagination-size: 95%;
    --pagination-font-size: var(--font-size-sm);
    --slide-width: 50%;
    --slider-width: 90%;
    --slider-padding: 8px 0 27px;

    --card-info-height: 76px;
    --card-more-info-height: 200px;

    --player-volume-display: none;
    --header-title-flex-direction: row;
    --header-toolbar-flex-direction: row;
    --header-toolbar-justify-content: space-between;

    --footer-padding: 0 var(--margin-sm);
    --footer-logo-height: 0.8rem;

    --search-form-padding: calc(var(--margin-sm) / 2) 0;
    --search-size: 90px;
    --switcher-size: 125px;
  }
}
