.cast {
  display: flex;
  flex-direction: column;
  gap: var(--margin-md);
}

.cast > p {
  font-size: calc(var(--font-size-xl) * 1.4);
  color: var(--header-color);
  letter-spacing: 1px;
}
.cast > ul {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, var(--person-size));
  gap: var(--margin-md);
  list-style: none;
}
