.overlay > :global(.ril-toolbar.ril__toolbar) {
  background-color: transparent;
}

.overlay > :global(.ril-inner.ril__inner) > img {
  display: block;
  border-radius: var(--border-radius-md);
  overflow: visible;
  z-index: 0;
  border: 4px solid var(--secondary-bgcolor);
}

.overlay > :global(.ril-inner.ril__inner) > img::after {
  position: absolute;
  display: block;
  content: "d";
  width: 110%;
  height: 110%;
  top: 0;
  left: 0;
  background-color: var(--secondary-bgcolor);
  border: 4px solid var(--secondary-bgcolor);
  transform: scale(1.1);
  z-index: 10000;
}

.overlay button {
  filter: brightness(0) saturate(100%) invert(51%) sepia(83%) saturate(5852%)
    hue-rotate(342deg) brightness(95%) contrast(116%);
  transform: scaleX(-1);
}
