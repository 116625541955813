.switcher {
  border: none;
  margin: auto var(--margin-sm);
  width: var(--switcher-size);
  height: 100%;
  color: var(--third-color);
  font-size: var(--font-size-md);
  font-weight: 300;
  text-transform: uppercase;
  transition: all var(--transition-speed-fast) ease;
}

.switcher > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switcher i {
  font-size: calc(var(--font-size-md) * 1.4);
  transition: all 0.2s ease;
}

.switcher i:hover {
  transform: scale(1.3);
}

.switcher span {
  transition: all 0.2s ease;
}

.switcher span:hover {
  letter-spacing: 0.5px;
}
