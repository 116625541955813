.list {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 95%;
  width: 100vw;
}

.list > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
