.videoPlayerSection {
  display: flex;
  flex-direction: column;
  gap: var(--margin-md);
}

.videoPlayerSection > p {
  font-size: calc(var(--font-size-xl) * 1.4);
  color: var(--header-color);
  letter-spacing: 1px;
}

.videoPlayerSection > div {
  position: relative;
  max-width: 60vh;
  border: 3px solid var(--primary-color);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.overlay {
  display: none;
  position: absolute;
  width: 60vh;
  height: 100%;
  background-color: var(--header-bgcolor);
  z-index: 1;
}

:global(vm-player) {
  --vm-icon-fill: var(--header-color);
  --vm-time-color: var(--header-color);
  --vm-time-font-weight: 500;
  --vm-slider-value-color: var(--secondary-bgcolor);
  --vm-slider-track-color: var(--secondary-bgcolor-opacity3);
  --vm-slider-thumb-bg: var(--header-color);
  --vm-scrubber-buffered-bg: var(--secondary-bgcolor-opacity3);
  --vm-tooltip-color: transparent;
  --vm-tooltip-bg: transparent;
  --vm-menu-bg: var(--secondary-bgcolor-opacity3);
  --vm-menu-item-focus-bg: var(--secondary-bgcolor-opacity);

  --vm-color-white-200: var(--secondary-bgcolor-opacity2);
  --vm-tooltip-border-radius: 0;
  --vm-tooltip-box-shadow: none;
}

:global(.ytp-pause-overlay.ytp-scroll-min) {
  display: none !important;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .overlay {
    display: none !important;
  }
}
